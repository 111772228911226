.comment-section {
    padding: 16px;
    background-color: #ffffff;
    border-radius: 6px;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.12);
    /* max-width: 500px; */
    /* You can adjust this as per your requirements */
}

.existing-comments {
    margin-top: 16px;
}

.comment {
    padding: 8px 0;
    border-bottom: 1px solid #e6e6e6;
}

.comment:last-child {
    border-bottom: none;
}

.comment strong {
    font-weight: 500;
    font-size: 14px;
}

.comment span {
    font-size: 12px;
    color: #a5a5a5;
}