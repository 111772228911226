@media print {
  body {
    color: black;
    font-size: 12px;
  }
  #invoiceArea {
    page-break-inside: avoid;
    font-size: 12px;
  }
  /* Other print-specific styles */
}
