.comment-section {
    padding: 16px;
    background-color: #ffffff;
    border-radius: 6px;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.12);
    /* max-width: 500px; */
    /* You can adjust this as per your requirements */
}

.existing-comments {
    margin-top: 16px;
    overflow-y: auto;
        /* Allow vertical scrolling */
    max-height: 300px;
        /* Adjust this value as per your requirements */
    position: relative;
}

.existing-comments::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 20px;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0));
    display: none;
}

.existing-comments.top-scrolled::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100px; 
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.15), transparent);
}

.comment {
    padding: 8px 0;
    border-bottom: 1px solid #e6e6e6;
}

.comment:last-child {
    border-bottom: none;
}

.comment strong {
    font-weight: 500;
    font-size: 14px;
}

.comment span {
    font-size: 12px;
    color: #a5a5a5;
}