/* App.css */
.app {
    display: flex;
}

.sidebar {
    width: 30%;
    border-right: 1px solid #ccc;
    padding: 20px;
    overflow-y: scroll;
}

.main-content {
    width: 70%;
    padding: 20px;
    overflow-y: scroll;
}

.message-item {
    padding: 10px;
    border-bottom: 1px solid #eee;
}

.message {
    margin-bottom: 10px;
}